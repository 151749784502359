import { useAuthQuery } from '@nhost/react-apollo'
import { graphql } from '../gql'
import React, { ComponentProps, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, TextInput } from '@8thday/react'
import { useNhostClient } from '@nhost/react'
import { useApolloClient } from '@apollo/client'
import { PencilIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline'

const PRESENTATION_LIST = graphql(`
  query getPresentationList {
    presentation {
      id
      name
      slug
    }
  }
`)

const CREATE_PRESENTATION = graphql(`
  mutation createPresentation($name: String!, $slug: String!) {
    insert_presentation_one(object: { name: $name, slug: $slug, slides: { data: { html: $name } } }) {
      id
    }
  }
`)

export interface PresentationListProps extends ComponentProps<'div'> {}

export const PresentationList = ({ className = '', ...props }: PresentationListProps) => {
  const nhost = useNhostClient()
  const client = useApolloClient()

  const [newName, setNewName] = useState('')

  const { data } = useAuthQuery(PRESENTATION_LIST)

  const presentations = data?.presentation ?? []

  return (
    <div className={`${className} p-4`} {...props}>
      <div className="flex flex-col sm:flex-row sm:justify-center">
        <TextInput
          className="sm:w-96"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Name your presentation"
          collapseDescriptionArea
        />
        <Button
          className="mt-2 self-end sm:ml-2 sm:mt-0"
          variant="primary"
          disabled={!newName}
          onClick={async () => {
            if (!newName) return

            const res = await nhost.graphql
              .request(CREATE_PRESENTATION, { slug: newName.toLowerCase().replace(/\s/, '-'), name: newName })
              .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

            if (res instanceof Error) {
              return console.error('oops', res)
            }

            client.reFetchObservableQueries()
          }}
        >
          Start A New One
        </Button>
      </div>
      <ul className="mx-auto mt-4 w-full max-w-3xl">
        {presentations.map((pres) => (
          <li key={pres.id} className="mb-2 flex rounded border border-gray-500 p-2">
            <strong className="mr-2">{pres.name}</strong>
            <NavLink to={pres.slug} className="flex-center ml-auto">
              <span className="hidden sm:inline">Edit</span> <PencilIcon className="ml-1 h-5 w-5" />
            </NavLink>
            <NavLink to={`/presenting/${pres.slug}`} className="flex-center ml-4">
              <span className="hidden sm:inline">Present</span> <PresentationChartLineIcon className="ml-1 h-5 w-5" />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
