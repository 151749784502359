import React from 'react'
import { createRoot } from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { App } from './App'
import { NhostClient, NhostProvider } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import { Loading } from './components/Loading'
import { ProtectedRoute } from './components/ProtectedRoute'
import { Login } from './components/Login'
import { PresentationList } from './components/PresentationList'
import { PresentationEditor } from './components/PresentationEditor'
import { ViewPresentation } from './components/ViewPresentation'
import { ChooseToView } from './components/ChooseToView'
import { Present } from './components/Present'
import { Home } from './components/Home'

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'my-presentations',
        element: <ProtectedRoute />,
        children: [
          {
            index: true,
            element: <PresentationList />,
          },
          {
            path: ':presentationSlug',
            element: <PresentationEditor />,
          },
        ],
      },
      {
        path: 'presenting',
        element: <ProtectedRoute />,
        children: [
          {
            index: true,
            element: <Navigate to="/my-presentations" replace />,
          },
          {
            path: ':presentationSlug',
            element: <Present />,
          },
        ],
      },
      {
        path: 'viewing',
        children: [
          {
            index: true,
            element: <ChooseToView />,
          },
          {
            path: ':presentationSlug',
            element: <ViewPresentation />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
])

const root = createRoot(document.getElementById('app')!)

root.render(
  <NhostProvider nhost={nhost}>
    <NhostApolloProvider nhost={nhost}>
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </NhostApolloProvider>
  </NhostProvider>
)
