import { useAuthQuery, useAuthSubscription } from '@nhost/react-apollo'
import React, { ComponentProps, useRef, useState } from 'react'
import { graphql } from '../gql'
import { useParams } from 'react-router-dom'
import { Loading } from './Loading'
import { SlideEditor } from './SlideEditor'
import clsx from 'clsx'
import { copyText, useRememberedState } from '@8thday/react'
import { CheckIcon, LinkIcon } from '@heroicons/react/24/outline'

const LIVE_PRESENTATION = graphql(`
  subscription livePresentation($id: Int!) {
    presentation_by_pk(id: $id) {
      id
      live
      public
      name
      slug
      current_slide_id
      slides(order_by: { order: asc }) {
        id
        html
        order
      }
    }
  }
`)

const PRESENTATION_ID = graphql(`
  query presentationId($slug: String!) {
    presentation(where: { slug: { _eq: $slug } }) {
      id
    }
  }
`)

export interface ViewPresentationProps extends ComponentProps<'div'> {}

export const ViewPresentation = ({ className = '', ...props }: ViewPresentationProps) => {
  const currentSlideRef = useRef(0)
  const { presentationSlug = '' } = useParams()

  const [copiedText, setCopiedText] = useState(false)

  const [freeSlideId, setFreeSlideId] = useRememberedState(`${presentationSlug}-viewer-free-slide-id`, 0)

  const { data: idData, loading: idLoading } = useAuthQuery(PRESENTATION_ID, { variables: { slug: presentationSlug } })

  const id = idData?.presentation?.[0]?.id ?? 0

  const { data, loading } = useAuthSubscription(LIVE_PRESENTATION, {
    variables: { id },
    skip: !presentationSlug || !id,
    onData(data) {
      const newCurrentSlideId = data.data.data?.presentation_by_pk?.current_slide_id

      if (currentSlideRef.current && newCurrentSlideId && currentSlideRef.current !== newCurrentSlideId) {
        setFreeSlideId(newCurrentSlideId)
      }

      if (newCurrentSlideId) {
        currentSlideRef.current = newCurrentSlideId
      }
    },
  })

  const prez = data?.presentation_by_pk
  const slides = data?.presentation_by_pk?.slides ?? []

  const slideIndex = slides.findIndex((s) => s.id === (prez?.public ? freeSlideId : prez?.current_slide_id))
  const currentSlide = slides[slideIndex]

  if (loading || idLoading) {
    return <Loading>Loading Presentation...</Loading>
  }

  if (!prez) {
    return (
      <Loading className="text-center text-xl">
        This presentation is either not public or non-existent.
        <br />
        Wait if you want, but you've been advised.
      </Loading>
    )
  }

  if (!prez.public && !prez.live) {
    return (
      <Loading className="text-center text-xl">
        <p>
          <strong className="font-bold">{prez.name}</strong> is not being presented at this time.
        </p>
      </Loading>
    )
  }

  if (!prez.public && prez.live && !prez.current_slide_id) {
    return (
      <Loading className="text-center text-xl">
        <p>
          <strong className="font-bold">{prez.name}</strong> will begin shortly...
        </p>
      </Loading>
    )
  }

  return (
    <div className={`${className} flex h-sm-contentS flex-col sm:h-contentS sm:flex-row`} {...props}>
      {prez.public && (
        <ul className="no-scrollbar flex max-w-full overflow-x-auto p-1 md:flex-col md:overflow-y-auto md:p-4">
          <li className="mr-1 flex w-full md:mr-0 md:mb-1">
            <button
              className={clsx(
                'flex-center w-full flex-col rounded border py-2 px-2 transition-colors',
                copiedText ? 'border-green-400 !py-1 !px-1 text-green-500' : 'border-blue-400 text-blue-500'
              )}
              onClick={() => {
                copyText(`https://liveslides.8thday.dev/viewing/${prez.slug}`).then(() => {
                  setCopiedText(true)
                  setTimeout(() => setCopiedText(false), 5000)
                })
              }}
            >
              {copiedText ? (
                <>
                  <span className="text-[8px]">Copied!</span>
                  <CheckIcon className="h-4 w-4" />
                </>
              ) : (
                <LinkIcon className="h-5 w-5" />
              )}
            </button>
          </li>
          {slides.map((s, i) => (
            <li key={s.id} className="mr-1 flex w-full md:mr-0 md:mb-1">
              <button
                className={clsx('w-full rounded border border-gray-600 px-3 py-1', {
                  'border-primary-600 bg-primary-400 text-white': s.id === freeSlideId,
                  'animate-pulse !border-green-600 !bg-green-400 text-white':
                    prez.live && s.id === prez.current_slide_id,
                })}
                onClick={() => {
                  setFreeSlideId(s.id)
                }}
              >
                {i}
              </button>
            </li>
          ))}
        </ul>
      )}
      {currentSlide && <SlideEditor className="grow" slideId={currentSlide.id} html={currentSlide.html} presentMode />}
    </div>
  )
}
