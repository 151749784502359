import { useUserDefaultRole } from '@nhost/react'
import clsx from 'clsx'
import React, { ComponentProps } from 'react'
import { NavLink } from 'react-router-dom'

export interface HomeProps extends ComponentProps<'div'> {}

export const Home = ({ className = '', ...props }: HomeProps) => {
  const isAnon = useUserDefaultRole() === 'anonymous'

  return (
    <div
      className={clsx(
        className,
        'prose prose-sm mx-auto mt-4 h-full p-2 sm:prose-base lg:prose-lg xl:prose-xl sm:mt-24'
      )}
      {...props}
    >
      <p className="sm:text-xl">
        <strong className="text-blue-500">Live Slides helps speakers 🗣️</strong> with{' '}
        <em className="text-red-300">inadequate presentation equipment</em> in{' '}
        <strong className="text-purple-500">large meeting spaces</strong> get their visual aids to the hands 🖐️ and eyes
        👀 of their <span className="text-green-500">audience</span> members.
      </p>
      {isAnon && (
        <NavLink to="/login" className="mx-auto mt-8 block text-center text-primary-500">
          Login
        </NavLink>
      )}
    </div>
  )
}
