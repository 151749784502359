import { useAuthenticationStatus, useNhostClient, useSignInAnonymous, useUserDefaultRole } from '@nhost/react'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { NavLink, Outlet, useMatch } from 'react-router-dom'

const navClasses =
  'flex-center grow flex-col whitespace-nowrap text-primary-400 transition-all duration-300 focus:outline-none sm:grow-0 sm:flex-row sm:px-4'
const activeClasses = 'bg-primary-200 text-primary-600'
const inactiveClasses = 'hover:bg-primary-100 focus:bg-primary-100'

export const App = () => {
  const nhost = useNhostClient()

  const loggingIn = !!useMatch('/login')
  const focusMode = !!useMatch('/viewing/:presentationSlug')

  const { isLoading, isAuthenticated } = useAuthenticationStatus()
  const isAnon = useUserDefaultRole() === 'anonymous'

  const { signInAnonymous } = useSignInAnonymous()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !loggingIn) {
      signInAnonymous()
    }
  }, [isLoading, isAuthenticated, loggingIn])

  return (
    <>
      <main className={`flex h-100svh max-w-screen flex-col pb-8 sm:pb-0 sm:pt-16`}>
        <Outlet />
      </main>
      <nav className="fixed bottom-0 flex h-8 w-full max-w-screen items-stretch justify-evenly bg-primary-50 transition-all duration-300 sm:top-0 sm:h-16 sm:justify-end">
        <h1
          className={clsx(
            'mr-auto items-center p-2 text-center text-lg font-bold italic leading-4 text-primary-500 sm:flex md:p-4 md:text-xl',
            isAuthenticated && !isAnon ? 'hidden' : 'ml-auto'
          )}
        >
          {focusMode ? 'Live Slides' : <NavLink to="/">Live Slides</NavLink>}
        </h1>
        {isAuthenticated && !isAnon && (
          <>
            <NavLink
              className={({ isActive }) => clsx(navClasses, isActive ? activeClasses : inactiveClasses)}
              to="/my-presentations"
            >
              My Presentations
            </NavLink>
            <NavLink
              className={({ isActive }) => clsx(navClasses, isActive ? activeClasses : inactiveClasses)}
              to="/viewing"
            >
              View Live
            </NavLink>
            <button className={clsx(navClasses, inactiveClasses)} onClick={() => nhost.auth.signOut()}>
              Logout
            </button>
          </>
        )}
      </nav>
    </>
  )
}
