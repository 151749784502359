import { Button, TextInput } from '@8thday/react'
import { useAuthenticated, useNhostClient, useSignInEmailPasswordless, useUserDefaultRole } from '@nhost/react'
import React, { ComponentProps, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

export interface LoginProps extends ComponentProps<'form'> {}

export const Login = ({ className = '', ...props }: LoginProps) => {
  const nhost = useNhostClient()

  const [email, setEmail] = useState('')
  const { signInEmailPasswordless } = useSignInEmailPasswordless()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const isAnon = useUserDefaultRole() === 'anonymous'
  const isAuthenticated = useAuthenticated()

  useEffect(() => {
    if (isAnon) {
      nhost.auth.signOut()
    }
  }, [isAnon, nhost])

  if (!isAnon && isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <form
      className={`${className} flex-center mx-auto min-h-full w-full flex-col overflow-y-auto p-4 sm:w-fit sm:p-1`}
      {...props}
      onSubmit={async (e) => {
        e.preventDefault()

        if (email) {
          const res = await signInEmailPasswordless(email, { redirectTo: '/' }).catch((err) =>
            err instanceof Error ? err : new Error(JSON.stringify(err))
          )

          if (!(res instanceof Error) && !res.error && !res.isError) {
            setSuccess(true)
          } else {
            setError(res instanceof Error ? res.message : res.error?.message ?? '')
          }
        }
      }}
    >
      <TextInput
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          setError('')
        }}
        placeholder="me@mycompany.com"
        className="w-full sm:w-96"
      />
      {error && <p className="text-center text-red-500">{error}</p>}
      {success ? (
        <p className="text-center text-green-400">A Magic Sign-in Link has been sent to your email!</p>
      ) : (
        <Button type="submit" variant="primary" disabled={!email || !!error} className="self-end">
          Login
        </Button>
      )}
    </form>
  )
}
