import { useEffect, useRef } from 'react'

export const useEventListener = <EventType extends Event>(event: string, callback: (e: EventType) => any) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    const listener = (e: EventType) => callbackRef.current(e)

    window.addEventListener(event, listener)

    return () => window.removeEventListener(event, listener)
  }, [event])
}
