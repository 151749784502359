import React, { ComponentProps } from 'react'

export interface LoadingProps extends ComponentProps<'div'> {}

export const Loading = ({ className = '', children, ...props }: LoadingProps) => {
  return (
    <div className={`${className} flex-center min-h-full animate-pulse`} {...props}>
      {children || 'Loading...'}
    </div>
  )
}
