/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  subscription livePresentation($id: Int!) {\n    presentation_by_pk(id: $id) {\n      id\n      live\n      public\n      name\n      slug\n      current_slide_id\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n": types.LivePresentationDocument,
    "\n  query presentationId($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n    }\n  }\n": types.PresentationIdDocument,
    "\n  mutation setCurrentSlide($id: Int!, $slideId: Int) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { current_slide_id: $slideId }) {\n      id\n    }\n  }\n": types.SetCurrentSlideDocument,
    "\n  mutation toggleLive($id: Int!, $live: Boolean!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { live: $live }) {\n      id\n    }\n  }\n": types.ToggleLiveDocument,
    "\n  subscription subToPresentation($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n      name\n      slug\n      live\n      public\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n": types.SubToPresentationDocument,
    "\n  mutation updatePresentation($id: Int!, $set: presentation_set_input!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: $set) {\n      id\n    }\n  }\n": types.UpdatePresentationDocument,
    "\n  mutation createSlide($presentationId: Int!) {\n    insert_slide_one(object: { presentation_id: $presentationId }) {\n      id\n    }\n  }\n": types.CreateSlideDocument,
    "\n                  mutation updateSlideOrder($id: Int!, $order: timestamptz!) {\n                    update_slide_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {\n                      id\n                    }\n                  }\n                ": types.UpdateSlideOrderDocument,
    "\n  query getPresentationList {\n    presentation {\n      id\n      name\n      slug\n    }\n  }\n": types.GetPresentationListDocument,
    "\n  mutation createPresentation($name: String!, $slug: String!) {\n    insert_presentation_one(object: { name: $name, slug: $slug, slides: { data: { html: $name } } }) {\n      id\n    }\n  }\n": types.CreatePresentationDocument,
    "\n  mutation updateSlideHTML($id: Int!, $html: String!) {\n    update_slide_by_pk(pk_columns: { id: $id }, _set: { html: $html }) {\n      id\n    }\n  }\n": types.UpdateSlideHtmlDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription livePresentation($id: Int!) {\n    presentation_by_pk(id: $id) {\n      id\n      live\n      public\n      name\n      slug\n      current_slide_id\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription livePresentation($id: Int!) {\n    presentation_by_pk(id: $id) {\n      id\n      live\n      public\n      name\n      slug\n      current_slide_id\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query presentationId($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query presentationId($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setCurrentSlide($id: Int!, $slideId: Int) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { current_slide_id: $slideId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation setCurrentSlide($id: Int!, $slideId: Int) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { current_slide_id: $slideId }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation toggleLive($id: Int!, $live: Boolean!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { live: $live }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation toggleLive($id: Int!, $live: Boolean!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: { live: $live }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription subToPresentation($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n      name\n      slug\n      live\n      public\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription subToPresentation($slug: String!) {\n    presentation(where: { slug: { _eq: $slug } }) {\n      id\n      name\n      slug\n      live\n      public\n      slides(order_by: { order: asc }) {\n        id\n        html\n        order\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updatePresentation($id: Int!, $set: presentation_set_input!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: $set) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updatePresentation($id: Int!, $set: presentation_set_input!) {\n    update_presentation_by_pk(pk_columns: { id: $id }, _set: $set) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createSlide($presentationId: Int!) {\n    insert_slide_one(object: { presentation_id: $presentationId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createSlide($presentationId: Int!) {\n    insert_slide_one(object: { presentation_id: $presentationId }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                  mutation updateSlideOrder($id: Int!, $order: timestamptz!) {\n                    update_slide_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {\n                      id\n                    }\n                  }\n                "): (typeof documents)["\n                  mutation updateSlideOrder($id: Int!, $order: timestamptz!) {\n                    update_slide_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {\n                      id\n                    }\n                  }\n                "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPresentationList {\n    presentation {\n      id\n      name\n      slug\n    }\n  }\n"): (typeof documents)["\n  query getPresentationList {\n    presentation {\n      id\n      name\n      slug\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createPresentation($name: String!, $slug: String!) {\n    insert_presentation_one(object: { name: $name, slug: $slug, slides: { data: { html: $name } } }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createPresentation($name: String!, $slug: String!) {\n    insert_presentation_one(object: { name: $name, slug: $slug, slides: { data: { html: $name } } }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateSlideHTML($id: Int!, $html: String!) {\n    update_slide_by_pk(pk_columns: { id: $id }, _set: { html: $html }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateSlideHTML($id: Int!, $html: String!) {\n    update_slide_by_pk(pk_columns: { id: $id }, _set: { html: $html }) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;