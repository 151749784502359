import { Editor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import clsx from 'clsx'
import React, { ComponentProps, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useNhostClient } from '@nhost/react'
import { graphql } from '../gql'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'

const UPDATE_SLIDE = graphql(`
  mutation updateSlideHTML($id: Int!, $html: String!) {
    update_slide_by_pk(pk_columns: { id: $id }, _set: { html: $html }) {
      id
    }
  }
`)

const buttonClasses =
  'whitespace-nowrap px-1 border border-black rounded bg-white text-sm hover:bg-gray-100 focus:bg-gray-200 focus:outline-none'

const activeClasses = '!bg-black hover:!bg-gray-800 focus:bg-gray-700 !text-white'

interface MenuBarProps extends ComponentProps<'div'> {
  showMobile: boolean
  setShowMobile: Dispatch<SetStateAction<boolean>>
  editor: Editor | null
}

const MenuBar = ({ editor, className = '', showMobile, setShowMobile }: MenuBarProps) => {
  const addImage = () => {
    const url = window.prompt('URL')

    if (url) {
      editor?.chain().focus().setImage({ src: url }).run()
    }
  }

  if (!editor) {
    return null
  }

  return (
    <div className={clsx(className, 'no-scrollbar flex max-w-full gap-1 overflow-x-auto px-1 md:flex-wrap')}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('bold') })}
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('italic') })}
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('strike') })}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('code') })}
      >
        code
      </button>
      <VerticalHR />
      <button
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive({ textAlign: 'left' }) })}
      >
        left
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive({ textAlign: 'center' }) })}
      >
        center
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive({ textAlign: 'right' }) })}
      >
        right
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive({ textAlign: 'justify' }) })}
      >
        justify
      </button>
      <VerticalHR />
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('paragraph') })}
      >
        paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 1 }) })}
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 2 }) })}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 3 }) })}
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 4 }) })}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 5 }) })}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('heading', { level: 6 }) })}
      >
        h6
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('bulletList') })}
      >
        bullet list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('orderedList') })}
      >
        ordered list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('codeBlock') })}
      >
        code block
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('blockquote') })}
      >
        blockquote
      </button>
      <button onClick={addImage} className={clsx(buttonClasses, { [activeClasses]: editor.isActive('image') })}>
        image
      </button>
      <button className={buttonClasses} onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </button>
      <button className={buttonClasses} onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </button>
      <VerticalHR />
      <button
        onClick={() => {
          const previousUrl = editor.getAttributes('link').href
          const url = window.prompt('URL', previousUrl)

          // cancelled
          if (url === null) {
            return
          }

          // empty
          if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run()

            return
          }

          // update link
          editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
        }}
        className={clsx(buttonClasses, { [activeClasses]: editor.isActive('link') })}
      >
        link
      </button>
      <button
        className={buttonClasses}
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
      >
        unset link
      </button>
      <VerticalHR />
      <button className={buttonClasses} onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear styles
      </button>
      <button className={buttonClasses} onClick={() => editor.chain().focus().undo().run()}>
        undo
      </button>
      <button className={buttonClasses} onClick={() => editor.chain().focus().redo().run()}>
        redo
      </button>
      <VerticalHR className="hidden sm:block" />
      <button
        className={clsx(buttonClasses, activeClasses, 'hidden sm:block')}
        onClick={() => setShowMobile((s) => !s)}
      >
        {showMobile ? 'mobile' : 'desktop'}
      </button>
    </div>
  )
}

export interface SlideEditorProps extends ComponentProps<'div'> {
  html: string
  slideId: number
  presentMode?: boolean
}

export const SlideEditor = ({ className = '', presentMode = false, html, slideId }: SlideEditorProps) => {
  const nhost = useNhostClient()
  const [showMobile, setShowMobile] = useState(true)
  const recentUpdate = useRef(Date.now())
  const lastSavedHtml = useRef(html)

  const editor = useEditor(
    {
      extensions: [StarterKit, Image, TextAlign.configure({ types: ['image', 'paragraph', 'heading'] }), Link],
      content: html,
      editorProps: {
        attributes: {
          spellcheck: 'false',
          class: `my-0 h-full w-full p-8 border border-gray-200 bg-white rounded mx-auto focus:outline-none overflow-y-auto`,
        },
      },
      editable: !presentMode,
    },
    [slideId, presentMode]
  )

  useEffect(() => {
    editor?.on('blur', async ({ editor }) => {
      const currentHtml = editor.getHTML()

      if (lastSavedHtml.current !== currentHtml) {
        lastSavedHtml.current = currentHtml

        const res = await nhost.graphql
          .request(UPDATE_SLIDE, { id: slideId, html: currentHtml })
          .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

        if (res instanceof Error) {
          console.error(res)
        }
      }
    })

    editor?.on('update', ({ editor }) => {
      const now = Date.now()
      recentUpdate.current = now
      const currentHtml = editor.getHTML()

      setTimeout(async () => {
        if (now === recentUpdate.current && currentHtml !== lastSavedHtml.current) {
          lastSavedHtml.current = currentHtml

          const res = await nhost.graphql
            .request(UPDATE_SLIDE, { id: slideId, html: currentHtml })
            .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

          if (res instanceof Error) {
            console.error(res)
          }
        }
      }, 2000)
    })

    return () => {
      editor?.off('update')
    }
  }, [slideId, editor])

  return (
    <div className={clsx(className, 'flex min-h-0 flex-col sm:px-2', { 'bg-gray-800': presentMode })}>
      {!presentMode && (
        <MenuBar className="mx-auto" editor={editor} showMobile={showMobile} setShowMobile={setShowMobile} />
      )}
      <EditorContent
        className={clsx(
          showMobile && !presentMode ? 'max-w-96' : 'max-w-full sm:prose-base lg:prose-lg',
          `prose prose-sm my-4 mx-auto min-h-0 w-full grow px-1 sm:px-0`
        )}
        editor={editor}
      />
    </div>
  )
}

interface VerticalHRProps extends ComponentProps<'div'> {}

const VerticalHR = ({ className = '', ...props }: VerticalHRProps) => {
  return <div className={clsx(className, 'mx-1 min-w-px self-stretch border-none bg-black')} {...props} />
}
