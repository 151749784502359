import React from 'react'
import { useAuthenticationStatus, useUserDefaultRole } from '@nhost/react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Loading } from './Loading'

export const ProtectedRoute = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  const isAnon = useUserDefaultRole() === 'anonymous'
  const location = useLocation()

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated || isAnon) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return <Outlet />
}
