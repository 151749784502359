import { Button, TextInput } from '@8thday/react'
import React, { ComponentProps, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface ChooseToViewProps extends ComponentProps<'form'> {}

export const ChooseToView = ({ className = '', ...props }: ChooseToViewProps) => {
  const [slug, setSlug] = useState('')

  const goTo = useNavigate()

  return (
    <form
      className={`${className} flex-center p-2`}
      {...props}
      onSubmit={(e) => {
        e.preventDefault()

        goTo(slug)
      }}
    >
      <div className="w-full max-w-md">
        <TextInput
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
          description="Enter the presentation identifier if you know it"
          placeholder="company-pres-1"
        />
        <Button type="submit" variant="primary" className="mt-4">
          View
        </Button>
      </div>
    </form>
  )
}
