import { useAuthSubscription } from '@nhost/react-apollo'
import React, { ComponentProps, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { graphql } from '../gql'
import { Button, TextInput, Toggle, copyText, toast, useRememberedState } from '@8thday/react'
import { Loading } from './Loading'
import { SlideEditor } from './SlideEditor'
import { useNhostClient } from '@nhost/react'
import { AdjustmentsHorizontalIcon, CheckIcon, LinkIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useApolloClient } from '@apollo/client'

const PRESENTATION_SUB = graphql(`
  subscription subToPresentation($slug: String!) {
    presentation(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
      live
      public
      slides(order_by: { order: asc }) {
        id
        html
        order
      }
    }
  }
`)

const UPDATE_PRESENTATION = graphql(`
  mutation updatePresentation($id: Int!, $set: presentation_set_input!) {
    update_presentation_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`)

const CREATE_SLIDE = graphql(`
  mutation createSlide($presentationId: Int!) {
    insert_slide_one(object: { presentation_id: $presentationId }) {
      id
    }
  }
`)

export interface PresentationEditorProps extends ComponentProps<'div'> {}

export const PresentationEditor = ({ className = '', ...props }: PresentationEditorProps) => {
  const client = useApolloClient()
  const nhost = useNhostClient()

  const { presentationSlug = '' } = useParams()

  const [copiedText, setCopiedText] = useState(false)

  const [showMetadata, setShowMetadata] = useRememberedState(`${presentationSlug}-show-metadata`, true)

  const [name, setName] = useState('')
  const [slug, setSlug] = useState(presentationSlug)
  const [live, setLive] = useState(false)
  const [publc, setPublic] = useState(false)

  const [slideIndex, setSlideIndex] = useState(0)

  const goTo = useNavigate()

  const loaded = useRef(false)
  const { data, loading } = useAuthSubscription(PRESENTATION_SUB, {
    variables: { slug: presentationSlug },
    skip: !presentationSlug,
    onData({ data }) {
      if (!loaded.current) {
        loaded.current = true
        setName(data.data?.presentation?.[0]?.name ?? '')
        setLive(data.data?.presentation?.[0]?.live ?? false)
        setPublic(data.data?.presentation?.[0]?.public ?? false)
      }
    },
  })

  const presentation = data?.presentation?.[0]

  const slides = presentation?.slides ?? []

  const slide = slides[slideIndex]

  if (!presentationSlug || (!loading && !presentation)) {
    return <Navigate to="/my-presentations" replace />
  }

  if (loading || !presentation) {
    return <Loading />
  }

  const dirty =
    presentation.name !== name ||
    presentation.slug !== slug ||
    presentation.live !== live ||
    presentation.public !== publc

  return (
    <div className={`${className} relative flex h-sm-contentS flex-col sm:h-contentS`} {...props}>
      <div
        className={clsx(
          'absolute inset-0 z-10 m-8 shrink-0 flex-col flex-wrap items-center justify-center gap-2 rounded-md border bg-white p-2 sm:static sm:m-0 sm:flex sm:flex-row sm:border-none',
          {
            flex: showMetadata,
            hidden: !showMetadata,
          }
        )}
      >
        <button className="absolute top-2 right-2 h-6 w-6 sm:hidden" onClick={() => setShowMetadata(false)}>
          <XMarkIcon />
        </button>
        <TextInput
          className="mr-2 w-full sm:w-fit"
          collapseDescriptionArea
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextInput
          className="mr-2 w-full sm:w-fit"
          collapseDescriptionArea
          label="Identifier"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
        <div className="flex flex-col justify-evenly self-stretch">
          <Toggle className="mb-2 sm:mr-2" rightLabel="Live" checked={live} setChecked={setLive} />
          <Toggle className="sm:mr-2" rightLabel="Public" checked={publc} setChecked={setPublic} />
        </div>
        <Button
          variant="primary"
          disabled={!dirty}
          onClick={async () => {
            const res = await nhost.graphql.request(UPDATE_PRESENTATION, {
              id: presentation.id,
              set: { slug, name, live, public: publc, current_slide_id: live === false ? null : undefined },
            })
            if (slug !== presentation.slug) {
              goTo(`/my-presentations/${slug}`)
            }
          }}
        >
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={dirty}
          onClick={() => {
            goTo(`/presenting/${slug}`)
          }}
        >
          Present
        </Button>
      </div>
      <div className="mt-2 flex max-h-contentS grow overflow-y-auto">
        <DragDropContext
          onDragEnd={async ({ destination, source, draggableId, reason }) => {
            if (!destination || reason !== 'DROP' || !source || !draggableId) {
              return console.log('no destination')
            }

            const order = getUpdatedOrder(
              slides[destination.index - 1]?.order,
              slides[destination.index]?.order,
              slides[destination.index + 1]?.order,
              destination.index,
              source.index
            )

            const id = Number(draggableId)

            const slide = slides.find((t) => t.id === id)

            if (!order || !slide) {
              return
            }

            client.cache.modify({ id: client.cache.identify(slide), fields: { order: () => order } })

            const res = await nhost.graphql
              .request(
                graphql(`
                  mutation updateSlideOrder($id: Int!, $order: timestamptz!) {
                    update_slide_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {
                      id
                    }
                  }
                `),
                { id, order }
              )
              .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

            if (res instanceof Error) {
              return toast.error({ message: 'Unable to reorder slide', description: res.message })
            }

            setSlideIndex(destination.index)
          }}
        >
          <div className="min-w-18 flex flex-col px-4">
            <button
              className="flex-center mb-1 rounded border border-gray-600 py-1 sm:hidden"
              onClick={async () => setShowMetadata((s) => !s)}
            >
              <AdjustmentsHorizontalIcon className="h-6 w-6" />
            </button>
            <button
              className={clsx('flex-center mb-1 flex-col rounded border border-gray-600 py-2', {
                'border-green-500 !py-1 text-green-500': copiedText,
              })}
              onClick={() => {
                copyText(`https://liveslides.8thday.dev/viewing/${presentation.slug}`).then(() => {
                  setCopiedText(true)
                  setTimeout(() => setCopiedText(false), 5000)
                })
              }}
            >
              {copiedText ? (
                <>
                  <span className="text-[8px]">Copied!</span>
                  <CheckIcon className="h-4 w-4" />
                </>
              ) : (
                <LinkIcon className="h-5 w-5" />
              )}
            </button>
            <Droppable droppableId="slides">
              {({ placeholder, droppableProps, innerRef }) => {
                return (
                  <ul className="flex flex-col" {...droppableProps} ref={innerRef}>
                    {slides.map((s, i) => (
                      <Draggable key={s.id} draggableId={`${s.id}`} index={i} isDragDisabled={false}>
                        {({ dragHandleProps, draggableProps, innerRef }) => (
                          <li ref={innerRef} className="mb-1 flex w-full" {...draggableProps} {...dragHandleProps}>
                            <div
                              className={clsx(
                                'inline-block w-full rounded border border-gray-600 px-3 py-1 text-center',
                                {
                                  'border-primary-600 bg-primary-400 text-white': i === slideIndex,
                                }
                              )}
                              onClick={() => setSlideIndex(i)}
                            >
                              {i}
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {placeholder}
                  </ul>
                )
              }}
            </Droppable>
            <button
              className="flex-center rounded border border-gray-600 py-1"
              onClick={async () => {
                const res = await nhost.graphql
                  .request(CREATE_SLIDE, { presentationId: presentation.id })
                  .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

                if (res instanceof Error) {
                  return console.error(res)
                }

                setSlideIndex(slides.length)
              }}
            >
              <PlusIcon className="h-6 w-6" />
            </button>
          </div>
        </DragDropContext>
        {slide && <SlideEditor slideId={slide.id} html={slide.html} className="grow overflow-y-auto" />}
      </div>
    </div>
  )
}

function getUpdatedOrder(
  lowerIndexOrder: string | undefined,
  replaced: string | undefined,
  higherIndexOrder: string | undefined,
  destIndex: number,
  srcIndex: number
): string | null {
  if (!replaced || srcIndex === destIndex) {
    return null
  }

  if (destIndex > srcIndex) {
    // replaced and higher index
    if (!higherIndexOrder) {
      return new Date(new Date(replaced).valueOf() - 2 * 60 * 60 * 1000).toISOString()
    }

    return new Date((new Date(replaced).valueOf() + new Date(higherIndexOrder).valueOf()) / 2).toISOString()
  }

  if (destIndex < srcIndex) {
    if (!lowerIndexOrder) {
      return new Date(new Date(replaced).valueOf() + 2 * 60 * 60 * 1000).toISOString()
    }

    return new Date((new Date(replaced).valueOf() + new Date(lowerIndexOrder).valueOf()) / 2).toISOString()
  }

  return null
}
