import { useAuthQuery, useAuthSubscription } from '@nhost/react-apollo'
import React, { ComponentProps, useState } from 'react'
import { graphql } from '../gql'
import { NavLink, useParams } from 'react-router-dom'
import { Loading } from './Loading'
import { useNhostClient } from '@nhost/react'
import { SlideEditor } from './SlideEditor'
import { ArrowLeftIcon, CheckIcon, LinkIcon } from '@heroicons/react/24/outline'
import { StopIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { copyText } from '@8thday/react'

const LIVE_PRESENTATION = graphql(`
  subscription livePresentation($id: Int!) {
    presentation_by_pk(id: $id) {
      id
      live
      public
      name
      slug
      current_slide_id
      slides(order_by: { order: asc }) {
        id
        html
        order
      }
    }
  }
`)

const PRESENTATION_ID = graphql(`
  query presentationId($slug: String!) {
    presentation(where: { slug: { _eq: $slug } }) {
      id
    }
  }
`)

const SET_CURRENT_SLIDE = graphql(`
  mutation setCurrentSlide($id: Int!, $slideId: Int) {
    update_presentation_by_pk(pk_columns: { id: $id }, _set: { current_slide_id: $slideId }) {
      id
    }
  }
`)

const TOGGLE_LIVE = graphql(`
  mutation toggleLive($id: Int!, $live: Boolean!) {
    update_presentation_by_pk(pk_columns: { id: $id }, _set: { live: $live }) {
      id
    }
  }
`)

export interface PresentProps extends ComponentProps<'div'> {}

export const Present = ({ className = '', ...props }: PresentProps) => {
  const nhost = useNhostClient()
  const { presentationSlug = '' } = useParams()

  const [copiedText, setCopiedText] = useState(false)

  const { data: idData, loading: idLoading } = useAuthQuery(PRESENTATION_ID, { variables: { slug: presentationSlug } })

  const id = idData?.presentation?.[0]?.id ?? 0

  const { data, loading } = useAuthSubscription(LIVE_PRESENTATION, {
    variables: { id },
    skip: !presentationSlug || !id,
  })

  const prez = data?.presentation_by_pk
  const slides = data?.presentation_by_pk?.slides ?? []

  const slideIndex = slides.findIndex((s) => s.id === prez?.current_slide_id)
  const currentSlide = slides[slideIndex]

  const setCurrentSlide = async (slideId: number | null) => {
    if (!prez) return

    const res = await nhost.graphql
      .request(SET_CURRENT_SLIDE, { id: prez?.id, slideId })
      .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

    if (res instanceof Error) {
      console.error(res)
    }
  }

  const toggleLive = async (live: boolean) => {
    if (!prez) return

    const res = await nhost.graphql
      .request(TOGGLE_LIVE, { id: prez?.id, live })
      .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

    if (res instanceof Error) {
      console.error(res)
    }
  }

  if (loading || idLoading) {
    return <Loading>Loading Presentation...</Loading>
  }

  if (!prez) {
    return (
      <Loading className="text-center text-xl">
        This presentation is either not public, not yours, or non-existent.
        <br />
        Either way, you probably wanted something else.
        <br />
        <NavLink to="..">Try Another</NavLink>
      </Loading>
    )
  }

  if (!prez.live) {
    return (
      <Loading className="flex-col text-center text-xl">
        <strong className="font-bold">{prez.name}</strong>
        <br />
        <button
          className="rounded border border-green-500 px-2 text-green-500 focus:outline-none focus:ring-2 focus:ring-green-200"
          onClick={async () => {
            toggleLive(true)
          }}
        >
          Go Live!
        </button>
      </Loading>
    )
  }

  return (
    <div className={`${className} relative flex h-sm-contentS flex-col sm:h-contentS md:flex-row`} {...props}>
      <ul className="no-scrollbar flex max-w-full overflow-x-auto p-1 md:flex-col md:overflow-y-auto md:p-4">
        <li className="mr-1 flex w-full md:mr-0 md:mb-1">
          <button
            className={clsx(
              'flex-center w-full flex-col rounded border py-2 px-2 transition-colors',
              copiedText ? 'border-green-400 !py-1 !px-1 text-green-500' : 'border-blue-400 text-blue-500'
            )}
            onClick={() => {
              copyText(`https://liveslides.8thday.dev/viewing/${prez.slug}`).then(() => {
                setCopiedText(true)
                setTimeout(() => setCopiedText(false), 5000)
              })
            }}
          >
            {copiedText ? (
              <>
                <span className="text-[8px]">Copied!</span>
                <CheckIcon className="h-4 w-4" />
              </>
            ) : (
              <LinkIcon className="h-5 w-5" />
            )}
          </button>
        </li>
        {slides.map((s, i) => (
          <li key={s.id} className="mr-1 flex w-full md:mr-0 md:mb-1">
            <button
              className={clsx('w-full rounded border border-gray-600 px-3 py-1', {
                'border-primary-600 bg-primary-400 text-white': i === slideIndex,
              })}
              onClick={() => {
                setCurrentSlide(s.id)
              }}
            >
              {i}
            </button>
          </li>
        ))}
        <li className="flex w-full">
          <button
            className={clsx('flex-center w-full rounded border border-gray-600 p-3', {
              'border-primary-600 bg-primary-400 text-white': !prez.live,
            })}
            onClick={() => {
              toggleLive(false)
              setCurrentSlide(null)
            }}
          >
            <StopIcon className="h-5 w-5 text-red-500" />
          </button>
        </li>
      </ul>
      {currentSlide ? (
        <SlideEditor className="grow" slideId={currentSlide.id} html={currentSlide.html} presentMode />
      ) : (
        <div className="flex-center h-full w-full">
          <ArrowLeftIcon className="mr-2 h-5 w-5" />
          Choose a slide to start!
        </div>
      )}
    </div>
  )
}
